<template>
    <Layout>
        <div class="row">
            <div class="col-12">
                <b-overlay :show="logList.length === 0" rounded="sm">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title">Visitor Logs</h4>
                            <div class="row mt-4">
                                <div class="col-sm-12 col-md-6">
                                    <div
                                        id="tickets-table_length"
                                        class="dataTables_length"
                                    >
                                        <label
                                            class="d-inline-flex align-items-center"
                                        >
                                            Show&nbsp;
                                            <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                            ></b-form-select
                                            >&nbsp;entries
                                        </label>
                                    </div>
                                </div>
                                <!-- Search -->
                                <div class="col-sm-12 col-md-6">
                                    <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-right"
                                    >
                                        <label
                                            class="d-inline-flex align-items-center"
                                        >
                                            Search:
                                            <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                            ></b-form-input>
                                        </label>
                                    </div>
                                </div>
                                <!-- End search -->
                            </div>
                            <!-- Table -->
                            <div class="table-responsive mb-0">
                                <b-table
                                    :items="logList"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    sort-icon-left
                                    :filter-included-fields="filterOn"
                                >
                                    <template v-slot:cell(visitor_type)="data">
                                        <p v-if="data.item.visitor_type == 1">
                                            Owner
                                        </p>
                                        <p v-if="data.item.visitor_type == 2">
                                            Tenant
                                        </p>
                                        <p v-if="data.item.visitor_type == 3">
                                            Other
                                        </p>
                                    </template>
                                    <template v-slot:cell(workassigned)="data">
                                        <span v-if="data">
                                            <span
                                                v-for="(user, index) in data
                                                    .item.workassigned"
                                                :key="index"
                                            >
                                                <p
                                                    v-if="
                                                        user.type ===
                                                        'AssignedTo'
                                                    "
                                                >
                                                    {{ user.username }}
                                                </p>
                                            </span>
                                        </span>
                                    </template>
                                    <template v-slot:cell(status)="data">
                                        <span
                                            v-for="stat in stats"
                                            :key="stat.id"
                                        >
                                            <p
                                                v-if="
                                                    stat.id == data.item.status
                                                "
                                                :id="stat.text.toLowerCase()"
                                            >
                                                {{ stat.text }}
                                            </p>
                                        </span>
                                    </template>
                                    <template v-slot:cell(action)="data">
                                        <b-button
                                            variant="primary"
                                            @click="view(data)"
                                            >Details</b-button
                                        >
                                    </template>
                                </b-table>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div
                                        class="dataTables_paginate paging_simple_numbers float-right"
                                    >
                                        <ul
                                            class="pagination pagination-rounded mb-0"
                                        >
                                            <!-- pagination -->
                                            <b-pagination
                                                v-model="currentPage"
                                                :total-rows="rows"
                                                :per-page="perPage"
                                            ></b-pagination>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-overlay>
            </div>
        </div>
    </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import { REQUEST_STATUS } from "../../common";

export default {
    components: {
        Layout,
    },
    data() {
        return {
            stats: REQUEST_STATUS,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "age",
            sortDesc: false,
            fields: [
                { key: "id", sortable: true },
                { key: "customer_name", sortable: true, label: "Visitor Name" },
                { key: "unit_no", sortable: true },
                { key: "visitor_type", sortable: true, label: "Visitor Type" },
                { key: "mobile_no", sortable: true, label: "Mobile" },
                { key: "concerned_person", sortable: true },
                { key: "company", sortable: true },
                { key: "workassigned", label: "Assigned To" },
                { key: "created_at", sortable: true },
                { key: "status" },
                "Action",
            ],
        };
    },
    mounted() {
        this.$store.dispatch(
            "apidata/fetchVisitorsList",
            localStorage.cs_user_token
        );
    },
    methods: {
        view(data) {
            console.log(data.item);
            this.$router.push({
                path: `/visitor-log/${data.item.id}`,
            });
        },
    },

    computed: {
        logList() {
            return this.$store.getters["apidata/getVisitorsList"];
        },
        rows() {
            return this.logList.length;
        },
    },
};
</script>
<style scoped>
#pending {
    color: #ff635c;
}
#started {
    color: #ffc71a;
}
#completed {
    color: #19b699;
}
#closed {
    color: #777;
}
#awaiting {
    color: #1b3863;
}
#rejected {
    color: #86583a;
}
</style>
